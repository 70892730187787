import React, { FC, useEffect } from "react";
import { Form, Radio, Space, Switch, Typography } from "antd";
import { parseInt } from "lodash";
import { useWatch } from "rc-field-form";

import { QuestionCircleFilled } from "@ant-design/icons";
import { useFormButtonDisabled, useReduxState } from "@ni/common/hooks";
import { BaseWizardPageProps, FormValues, SliderRanges } from "@ni/common/types";
import {
  CustomFormWrapper,
  FormItemLabel,
  InputRangeSlider,
  InputRangeSlider8Dig,
  NetworkForm,
  TooltipInfo,
} from "@ni/common/ui";
import { getFormValueFromProductValues } from "@ni/common/utils";
import { ProductAndTenantWizardApi } from "@ni/sdk/apis";
import { ProductValue, TenantProductWizardRequest, TenantProductWizardResponse } from "@ni/sdk/models";

const { Text } = Typography;

const wizardServicesApi = new ProductAndTenantWizardApi();

export const CardNumberingPage: FC<BaseWizardPageProps> = ({ formDisabled }) => {
  const [form] = Form.useForm<FormValues>();

  const [wizardResponse, setWizardResponse] = useReduxState<TenantProductWizardResponse>("wizard", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");

  const [isButtonDisabled, onFormChange] = useFormButtonDisabled({ form });

  const isNetworkTestPin = !!useWatch("use-test-bin", form);

  const cleanUpBin = () => {
    const values = form.getFieldsValue();

    const slides: SliderRanges = {
      "nic-card-subtype-pan-full-min": "0000000000",
      "nic-card-subtype-pan-full-max": "9999999999",
    };

    if (values["use-test-bin"]) {
      form.setFieldsValue({
        "nic-card-subtype-pan-bin": null,
        sliderRanges: slides,
        "bin-length": 8,
      });
    }

    void form.validateFields();
  };

  const cleanUpBinDigits = () => {
    const values = form.getFieldsValue();

    const slides: SliderRanges = {
      "nic-card-subtype-pan-full-min": values["bin-length"] === 6 ? "0000000000" : "00000000",
      "nic-card-subtype-pan-full-max": values["bin-length"] === 6 ? "9999999999" : "99999999",
    };

    form.setFieldsValue({
      "nic-card-subtype-pan-bin": null,
      sliderRanges: slides,
    });

    void form.validateFields();
  };

  useEffect(() => {
    const binLength =
      getFormValueFromProductValues(wizardResponse.product?.productValues as ProductValue[], "bin-length", "number") ||
      8;

    const binLengthZeros = binLength === 6 ? "0000000000" : "00000000";
    const binLengthNines = binLength === 6 ? "9999999999" : "99999999";

    const slides: SliderRanges = {
      "nic-card-subtype-pan-full-min": formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.productValues as ProductValue[],
            "nic-card-subtype-pan-full-min",
            "string",
          ) as string)
        : binLengthZeros,
      "nic-card-subtype-pan-full-max": formDisabled
        ? (getFormValueFromProductValues(
            wizardResponse.product?.productValues as ProductValue[],
            "nic-card-subtype-pan-full-max",
            "string",
          ) as string)
        : binLengthNines,
    };

    form.setFieldsValue({
      "use-test-bin": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.productValues as ProductValue[],
            "use-test-bin",
            "boolean",
          )
        : false,
      "nic-card-subtype-pan-bin": formDisabled
        ? getFormValueFromProductValues(
            wizardResponse.product?.productValues as ProductValue[],
            "nic-card-subtype-pan-bin",
            "number",
          )
        : "",
      "ica-number-mc": getFormValueFromProductValues(wizardResponse.product?.productValues, "ica-number-mc") || "",
      "rep-bin-visa": getFormValueFromProductValues(wizardResponse.product?.productValues, "rep-bin-visa") || "",
      sliderRanges: slides,
      "bin-length": binLength,
      "nic-prod-ips": getFormValueFromProductValues(
        wizardResponse.product?.productValues as ProductValue[],
        "nic-prod-ips",
        "string",
      ),
      "nic-prod-parms-ica-number": getFormValueFromProductValues(
        wizardResponse.product?.productValues as ProductValue[],
        "nic-prod-parms-ica-number",
        "string",
      ),
    });
  }, [form, formDisabled, wizardResponse.product?.productValues]);

  const checkRanges = (
    _: unknown,
    value: { "nic-card-subtype-pan-full-min": string; "nic-card-subtype-pan-full-max": string },
  ) => {
    const values = form.getFieldsValue();
    const minRange = value["nic-card-subtype-pan-full-min"];
    const maxRange = value["nic-card-subtype-pan-full-max"];

    if (
      minRange.length < (values["bin-length"] === 6 ? 10 : 8) ||
      maxRange.length < (values["bin-length"] === 6 ? 10 : 8)
    ) {
      return Promise.reject(new Error(`${values["bin-length"] === 6 ? 10 : 8} digits are required!`));
    }

    if (parseInt(minRange) > parseInt(maxRange)) {
      return Promise.reject(new Error("Min value should be lower than Max value!"));
    }

    if (parseInt(minRange) === parseInt(maxRange))
      return Promise.reject(new Error("Min value and Max value can't be the same"));

    return Promise.resolve();
  };

  const onFinish = (values: FormValues) => {
    setIsLoading(true);
    const sliderRanges = { ...(values["sliderRanges"] as SliderRanges) };
    delete values["sliderRanges"];
    delete values["nic-pros-ips"];

    const wizardRequest: TenantProductWizardRequest = {
      tenantId: wizardResponse.tenant?.id,
      pageId: wizardResponse.pageId,
      productId: wizardResponse.product?.id,
      collectedValues: {
        ...values,
        "nic-card-subtype-pan-full-min": `${sliderRanges["nic-card-subtype-pan-full-min"]}`,
        "nic-card-subtype-pan-full-max": `${sliderRanges["nic-card-subtype-pan-full-max"]}`,
      },
    };

    wizardServicesApi
      .processWizardRequest(wizardRequest)
      .then(response => {
        setWizardResponse(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomFormWrapper
      form={form}
      disabled={formDisabled}
      buttonDisabled={isButtonDisabled}
      pageTitle="Card Numbering"
      size="sm"
      formSize="sm"
      gap={18}
      level="root"
      onValuesChange={onFormChange}
      submitHandler={onFinish}
    >
      <div className="text-blue-h3">
        <TooltipInfo
          label="Bank Identification number (BIN)"
          tooltipProps={{
            title:
              "Each product is associated with BIN (Bank Identification Number). BIN's will be provided by payment schemes. PAN generation is based on BIN.",
          }}
        />
      </div>
      <Space direction="horizontal">
        <Form.Item name="use-test-bin" valuePropName="checked">
          <Switch onChange={cleanUpBin} />
        </Form.Item>
        <FormItemLabel label="Use Network test BIN if you haven't got the real one" code="use-test-bin" />
      </Space>
      <Form.Item dependencies={["use-test-bin"]}>
        {() => (
          <Form.Item
            name="bin-length"
            label={<FormItemLabel label="What is the length of product BIN?" code="bin-length" />}
            className="wizard-input input-group-label"
            rules={
              formDisabled
                ? []
                : [{ required: !form.getFieldValue("use-test-bin"), message: "BIN Length is required." }]
            }
          >
            <Radio.Group
              disabled={!!form.getFieldValue("use-test-bin") || formDisabled || isNetworkTestPin}
              onChange={cleanUpBinDigits}
            >
              <Space direction="vertical">
                <Radio value={6} key={1}>
                  <FormItemLabel label="6 digit" code="6" />
                </Radio>
                <Radio value={8} key={2}>
                  <FormItemLabel label="8 digit (default)" code="8" />
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
      </Form.Item>
      <div>
        <Form.Item noStyle={true} dependencies={["use-test-bin", "bin-length"]}>
          {() => (
            <Form.Item
              name="nic-card-subtype-pan-bin"
              label={<FormItemLabel label="BIN" code="nic-card-subtype-pan-bin" />}
              className="wizard-input"
              rules={
                formDisabled
                  ? []
                  : [
                      { required: !form.getFieldValue("use-test-bin"), message: "BIN is required." },
                      {
                        pattern: form.getFieldValue("use-test-bin")
                          ? undefined
                          : form.getFieldValue("bin-length") === 6
                            ? /^[0-9]{6}$/
                            : /^[0-9]{8}$/,
                        message: `BIN value must have ${form.getFieldValue("bin-length") as string} digits.`,
                      },
                    ]
              }
            >
              <NetworkForm.Number
                placeholder="BIN"
                disabled={!!form.getFieldValue("use-test-bin") || formDisabled || isNetworkTestPin}
                formatter={undefined}
                parser={undefined}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle={true} dependencies={["bin-length"]}>
          {() => (
            <Text className="input-info">
              {`The first ${form.getFieldValue("bin-length") as string} digits of the card number for the product.`}
            </Text>
          )}
        </Form.Item>
      </div>

      <Form.Item dependencies={["nic-prod-ips"]}>
        {() =>
          form.getFieldValue("nic-prod-ips") === "Visa" ? (
            <Form.Item
              name="rep-bin-visa"
              label={<FormItemLabel label="Member ID" code="rep-bin-visa" />}
              className="wizard-input"
              rules={
                formDisabled
                  ? []
                  : [
                      {
                        pattern: /^[0-9]{1,6}$/,
                        message: `Processing BIN number must have maximum 6 digits.`,
                      },
                    ]
              }
              tooltip={{
                title:
                  "Center Processing BIN (Reporting BIN) assigned by Visa to a financial institution, third party processor, or other member to identify the member in transactions",
                icon: <QuestionCircleFilled />,
              }}
            >
              <NetworkForm.Number placeholder="BIN number" />
            </Form.Item>
          ) : (
            form.getFieldValue("nic-prod-ips") === "MC" && (
              <Form.Item
                name="ica-number-mc"
                label={<FormItemLabel label="ICA number" code="ica-number-mc" />}
                className="wizard-input"
                rules={
                  formDisabled
                    ? []
                    : [
                        { required: true, message: "ICA number is required." },
                        {
                          pattern: /^[0-9]{1,6}$/,
                          message: `ICA number must have maximum 6 digits.`,
                        },
                      ]
                }
                tooltip={{
                  title:
                    "ICA (Interbank Card Association Number) assigned by MasterCard to a financial institution, third party processor, or other member to identify the member in transactions",
                  icon: <QuestionCircleFilled />,
                }}
              >
                <NetworkForm.String placeholder="ICA number" />
              </Form.Item>
            )
          )
        }
      </Form.Item>

      <div className="text-blue-h3">
        <TooltipInfo
          label="BIN subranges"
          code="nic-card-subtype-pan-full-min, nic-card-subtype-pan-full-max"
          tooltipProps={{
            title: (
              <>
                <p>
                  Card number (PAN) is 16 digit number which comprises of
                  {form.getFieldValue("bin-length") === 6 ? " 6 digit BIN + 10 digits " : " 8 digit BIN + 8 digits "}
                  of allowed range by payment scheme. Issuer can choose PAN range based on allowed range by payment
                  scheme. This parameters together with BIN specify the minimum and maximum number in allowed range for
                  the product in generation of PAN.
                </p>
                <p>
                  It is recommend selecting a sub-range that would allow multiple product launches under the same BIN
                  and not launching a product across the whole BIN range.
                </p>
              </>
            ),
          }}
        />
      </div>

      <Form.Item noStyle={true} dependencies={["nic-card-subtype-pan-bin", "bin-length"]}>
        {() => (
          <Form.Item
            name="sliderRanges"
            className="wizard-input"
            rules={[
              {
                validator: checkRanges,
              },
            ]}
          >
            {form.getFieldValue("bin-length") === 6 ? (
              <InputRangeSlider form={form} disabled={formDisabled} />
            ) : (
              <InputRangeSlider8Dig form={form} disabled={formDisabled} />
            )}
          </Form.Item>
        )}
      </Form.Item>
    </CustomFormWrapper>
  );
};
